import { assert } from "./assert";
import { ColorMap } from "./color-palette-definition.generated";
/**
 * Utility class which helps working with the new DVAG color palette.
 */
export class ColorPalette {
    /**
     * Returns the hex code of a specific color.
     * @param colorName The name of the color.
     */
    static getColorCode(colorName) {
        const lowerCaseColor = colorName.toLowerCase();
        return this.getNewColorCode(lowerCaseColor);
    }
    static getNewColorCode(colorName) {
        const hexCode = getColorCode(colorName);
        assert(hexCode != null, () => getErrorMessage(colorName, getColorAliases()));
        return hexCode;
    }
    /**
     * Returns color names from a wheel which holds specific colors in order. (e.g. useful for a chart diagram)
     * @param index The index of the color which should be returned.
     * @return The name of the color.
     */
    static getWheelColor(index) {
        return getWheelColorAlias(index);
    }
    /**
     * Returns hex colors from a wheel which holds specific colors in order. (e.g. useful for a chart diagram)
     * @param index The index of the color which should be returned.
     * @return The hex code of the color.
     */
    static getWheelColorCode(index) {
        return this.getColorCode(this.getWheelColor(index));
    }
}
function getErrorMessage(colorName, aliases) {
    return `There is no color with the name '${colorName}'. Possible names: \n${aliases.join("\n")}`;
}
const WHEEL_COLORS = ["gold", "default", "error", "warning"];
function getColorAliases() {
    return Object.values(ColorMap)
        .flat()
        .map((alias) => alias);
}
function getColorCode(color) {
    return Object.keys(ColorMap).find((hexCode) => {
        return ColorMap[hexCode].includes(color);
    });
}
function getWheelColorAlias(index) {
    return WHEEL_COLORS[index];
}
