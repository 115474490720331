export const ColorMap = {
    "#282207": ["gold-180"],
    "#50440e": ["gold-160"],
    "#786614": ["gold-140"],
    "#a0881b": ["gold-120"],
    "#c8aa22": ["gold-100", "gold", "logo", "golden-stage"],
    "#d3bb4e": ["gold-80"],
    "#decc7a": ["gold-60"],
    "#e9dda7": ["gold-40"],
    "#f4eed3": ["gold-20"],
    "#00090c": ["blue-190"],
    "#001219": ["blue-180"],
    "#001a25": ["blue-170"],
    "#002332": ["blue-160"],
    "#002c3e": ["blue-150"],
    "#00354a": ["blue-140"],
    "#003e57": ["blue-130"],
    "#004763": ["blue-120", "active"],
    "#004f70": ["blue-110"],
    "#00587c": ["blue-100", "hover"],
    "#196989": ["blue-90"],
    "#337a96": ["blue-80", "default"],
    "#4c8aa3": ["blue-70"],
    "#669bb0": ["blue-60"],
    "#80acbe": ["blue-50"],
    "#99bdcb": ["blue-40"],
    "#b2cdd8": ["blue-30"],
    "#ccdee5": ["blue-20"],
    "#e5eef2": ["blue-10"],
    "#181f00": ["green-180"],
    "#313e00": ["green-160"],
    "#495c01": ["green-140"],
    "#617b01": ["green-120"],
    "#7a9a01": ["green-100", "success"],
    "#94ae34": ["green-80"],
    "#afc267": ["green-60"],
    "#cad799": ["green-40"],
    "#e4ebcc": ["green-20"],
    "#1f070b": ["red-180"],
    "#3f0e15": ["red-160"],
    "#5e141f": ["red-140"],
    "#7e1b2a": ["red-120"],
    "#9d2235": ["red-100", "error"],
    "#b14e5d": ["red-80"],
    "#c47a85": ["red-60"],
    "#d8a7ae": ["red-40"],
    "#ebd3d7": ["red-20"],
    "#2e1807": ["orange-180"],
    "#5d300e": ["orange-160"],
    "#8b4714": ["orange-140"],
    "#ba5f1b": ["orange-120"],
    "#e87722": ["orange-100", "warning"],
    "#ed924e": ["orange-80"],
    "#f1ad7a": ["orange-60"],
    "#f6c9a7": ["orange-40"],
    "#fae4d3": ["orange-20"],
    "#001715": ["turquoise-180"],
    "#002e29": ["turquoise-160"],
    "#00453e": ["turquoise-140"],
    "#005c53": ["turquoise-120"],
    "#007367": ["turquoise-100"],
    "#338f86": ["turquoise-80"],
    "#66aba4": ["turquoise-60"],
    "#99c7c2": ["turquoise-40"],
    "#cce3e1": ["turquoise-20"],
    "#13011c": ["purple-180"],
    "#250238": ["purple-160"],
    "#380454": ["purple-140"],
    "#4a0570": ["purple-120"],
    "#5c068c": ["purple-100"],
    "#7d38a3": ["purple-80"],
    "#9e6aba": ["purple-60"],
    "#be9bd1": ["purple-40"],
    "#dfcde8": ["purple-20"],
    "#000000": ["black"],
    "#2b2b2b": ["gray-83", "headline"],
    "#575757": ["gray-66", "paragraph"],
    "#808080": ["gray-50", "tags", "icons"],
    "#ababab": ["gray-33"],
    "#d6d6d6": ["gray-16", "border"],
    "#ededed": ["gray-7", "footer"],
    "#f7f7f7": ["gray-3", "background"],
    "#ffffff": ["white"],
    transparent: ["transparent"],
};
